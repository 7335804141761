<template>
  <div class="edit-site-widget">
    <div class="error">{{ error }}</div>

    <div id="statusBar">
      <div class="center">
        <h3>Search</h3>
        <label for="centerLat">Latitude</label>
        <input
          type="text"
          name="centerLat"
          v-model="centerLat"
          @input="setCenter"
        />

        <label for="centerLng">Longitude</label>
        <input
          type="text"
          name="centerLng"
          v-model="centerLng"
          @input="setCenter"
        />
      </div>
      <div class="current" v-if="currentCoords">
        {{ currentCoords.lat.toFixed(3) + ", " + currentCoords.lng.toFixed(3) }}
      </div>
    </div>
    <div class="target">
      <h3>Editing layer</h3>
      <select name="editLayer" v-model="editLayer">
        <option value="__boundary">Boundaries</option>
        <option value="__building">Buildings</option>
      </select>
    </div>
    <div class="upload">
      <h3>Upload GeoJSON to current layer</h3>
      <input
        type="file"
        ref="uploadBoundary"
        name="uploadBoundary"
        @change="uploadBoundary"
        accept=".json,.geojson"
      />
    </div>

    <div id="map-wrapper">
      <map-widget
        ref="map"
        :id="mapId"
        :center="center"
        :defaultZoom="siteZoom"
        :zoomable="true"
        :editable="true"
        :draggable="true"
        :width="window.innerWidth"
        :height="769"
        mapboxStyle="mapbox://styles/maupalantir/cl3wrrary000414qxs3zt5pn2"
        :boundaries="boundaries"
        :objects="implementation"
        :objectTypes="mapObjectTypes"
        :displayOnly="displayOnly"
        :rotate="rotate"
        @input="setBoundaries"
      />
    </div>
  </div>
</template>
<script>
import MapWidget from "./MapWidget";
import { featureCollection, centerOfMass } from "@turf/turf";
import { setDrawMode } from "../mapObjectTypes";

export default {
  components: { MapWidget },
  props: {
    site: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      error: "",
      center: [],
      centerLat: "",
      centerLng: "",
      currentCoords: null,
      boundaries: null,
      siteInternal: null,
      mapId: "map-scenario-site-",
      id: null,
      mapObjectTypes: {},
      siteZoom: 4,
      indicators: [],
      implementation: { type: "FeatureCollection", features: [] },
      displayOnly: featureCollection([]),
      warning: null,
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      editLayer: "__boundary",
    };
  },
  created() {
    this.id = this.site._id;
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.map.map.on("load", () => {
        this.loadMapObjectTypes();
        this.$refs.map.addDraw();

        // this.$refs.map.objectTypeControls();
        this.boundaries = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [-180, -90],
                [-180, 90],
                [180, 90],
                [180, -90],
                [-180, -90],
              ],
            ],
          },
        };
        this.center = centerOfMass(this.boundaries).geometry.coordinates;

        if (this.site.boundaries) {
          this.center = centerOfMass(this.site.boundaries).geometry.coordinates;
          this.implementation = featureCollection([this.site.boundaries]);
          if (this.site.buildings) this.displayOnly = this.site.buildings;
          this.siteZoom = this.site.siteZoom || 13;
        }

        this.centerLat = this.center[0].toFixed(2);
        this.centerLng = this.center[1].toFixed(2);

        this.$refs.map.map.on("mousemove", (e) => {
          this.currentCoords = e.lngLat.wrap();
        });
      });
    });
  },
  watch: {
    editLayer(value) {
      switch (value) {
        case "__boundary":
          this.boundaries = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [-180, -90],
                  [-180, 90],
                  [180, 90],
                  [180, -90],
                  [-180, -90],
                ],
              ],
            },
          };
          this.displayOnly = this.site.buildings;
          this.implementation = featureCollection([this.site.boundaries]);
          break;
        case "__building":
          this.boundaries = this.site.boundaries;
          this.displayOnly = featureCollection([]);
          this.implementation = this.site.buildings;
          break;
      }
    },
  },
  computed: {
    rotate() {
      if (this.site) {
        return parseFloat(this.site.rotate) || 0;
      } else {
        return 0;
      }
    },
    isModeSelected() {
      return this.$refs.map && this.$refs.map.modeSelected;
    },
  },
  methods: {
    stopDrawing() {
      this.$refs.map.resetListener();
      this.$refs.map.modeSelected = false;
      this.info = "";
    },
    // Load NBS Type definitions and load them into a place where the map can use it.
    loadMapObjectTypes() {
      this.mapObjectTypes["__boundary"] = {
        _id: "__boundary",
        name: "Site boundary",
        object: {
          type: "Polygon",
          icon: "🗺️",
          options: {
            color: "#000000",
            fill: "false",
          },
        },
      };
      this.mapObjectTypes["__building"] = {
        _id: "__building",
        name: "Building",
        object: {
          type: "Polygon",
          icon: "🗺️",
          options: {
            color: "#000000",
            fill: "false",
            building: true,
          },
        },
      };
      this.$refs.map.addObjectModes();
    },
    startDrawing() {
      setDrawMode(this.$refs.map, this.mapObjectTypes[this.editLayer]);
    },
    saveBoundary(features) {
      const feature = features[0];
      if (!feature || !feature.geometry || !feature.geometry.coordinates) {
        this.error = "No polygon found in geoJSON";
        return;
      } else {
        this.site.boundaries = feature;
        this.saveSite();
      }
    },
    saveBuildings(features) {
      this.site.buildings = featureCollection(
        features.features.map((f) => {
          f.properties.building = true;
          f.properties.objectType = "__building";
          return f;
        })
      );
      this.saveSite();
    },
    uploadBoundary(event) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = event.target.result;
        try {
          const json = JSON.parse(result);
          let features = {};
          switch (json.type) {
            case "Feature":
              features = { type: "FeatureCollection", features: [json] };
              break;
            case "FeatureCollection":
              features = json; // json.features.filter((f) => {
              // return f.geometry.type === 'Polygon'
              // })
              break;
            default:
              this.error = "Could not parse geoJSON features.";
              return;
          }

          switch (this.editLayer) {
            case "__boundary":
              this.saveBoundary(features);
              break;
            case "__building":
              this.saveBuildings(features);
              break;
          }

          this.$refs.uploadBoundary.value = null;
        } catch (e) {
          this.error = "Not a valid JSON file";
        }
      };
      reader.readAsText(event.target.files[0]);
    },
    setCenter() {
      this.center = [this.centerLat, this.centerLng];
    },
    setBoundaries(newObjects, objects) {
      if (this.editLayer === "__boundary") {
        const features = objects.features.reverse();

        this.site.boundaries = features.find((f) => {
          return f.properties.objectType == "__boundary";
        });
        this.implementation = featureCollection([this.site.boundaries]);
      } else if (this.editLayer === "__building") {
        this.site.buildings = featureCollection(
          objects.features.map((f) => {
            f.properties.objectType = "__building";
            return f;
          })
        );
        this.implementation = this.site.buildings;
      }

      this.saveSite();
    },
    saveSite() {
      this.error = "";
      this.site.siteZoom = this.$refs.map.map.getZoom() || 13;

      if (!this.id) {
        this.$store
          .dispatch("createSite", this.site)
          .then((site) => {
            this.site = site;
            this.id = site._id.toString();
            this.$emit("create", site);
          })
          .catch((e) => {
            this.$emit("error", "Unable to create site: " + e.toString());
          });
      } else {
        this.$store
          .dispatch("saveSite", this.site)
          .then(() => {
            this.$store.commit("setSite", this.site);
            this.$emit("save", this.site);
          })
          .catch((e) => {
            this.$emit("error", "Unable to save site data: " + e.toString());
          });
      }
    },
  },
};
</script>
<style lang="scss">
#admin-debug {
  background: white;
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 350px;
  padding: 20px;
}

#statusBar {
  display: flex;
  justify-content: space-between;
  * {
    align-self: center;
  }
}
div.center {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  label {
    display: inline-block;
    width: 100px;
  }
  input {
    display: inline-block;
    width: 50px;
    padding: 5px 10px;
    font-size: 10pt;
    border: solid 1px grey;
  }
}
.upload {
  display: flex;
  * {
    align-self: center;
  }
  input {
    border: none;
  }
}
</style>
